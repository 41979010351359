 *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
 }


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.payment_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    flex-direction: column;
    gap: 3rem;
    margin-top: 8rem;
    padding: 2rem;
  }
  
  .payment_card {
    background-color: #fff;
    padding: 1.875rem;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  
    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      color: #333;
    }
  
    .amount_input {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
    }
  
    .pay_button {
      width: 100%;
      padding: 12px;
      background-color: #4CAF50;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #45A049;
      }
    }

  
  }

  .timer{
    margin-bottom: 1rem;
  }
  